import type { NextPage } from 'next'
import Head from 'next/head'
import { ErrorTemplate } from '~/components/error-template'

const title = 'Missing Session'
const MissingSession: NextPage = () => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content="There is no active session" />
      </Head>
      <div data-cy="error-missing-session">
        <ErrorTemplate
          statusCode={401}
          title="Looks like there is no active session"
        />
      </div>
    </>
  )
}

export default MissingSession
